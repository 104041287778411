
 export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Nossa Frota</h2>
          <p>
          Possuímos frota com veículos de diversos portes totalmente padronizados, guiados por funcionários capacitados e treinados.
        </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
          <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}

                  
                <a
                    href='img/caminhao3.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                
                    <img
                      src='img/caminhao3.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/fiorino1.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                
                    <img
                      src='img/fiorino1.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/van1.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/van1.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/caminhao5.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/caminhao5.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/caminhao 4.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/caminhao 4.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/caminhao2.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <img
                      src='img/caminhao2.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '} 
                </div>
              </div>
            </div>
            
           
          </div>
        </div>
      </div>
    </div>
  )
} 


 