import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
/* import React,{Component} from 'react';   */
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
 



export const Team = (props) => {
  
  return (
    
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Nossos Parceiros</h2>
          <p>
            
          </p>
        </div>
        <div id='row'>
      
        <div>  
            <div  className = 'container-fluid'  >      
            <div className = "título da linha"  style = {{marginBottom:  "20px" }} >      
                
            </div>  
        </div>  
        <div  className = 'container-fluid'  >   
        
        
          <OwlCarousel
           items = {7}
           margin = {5} 
           autoplay = { true } 
           loop = {true}
           responsiveClass={true}
           autoplaySpeed ={3000}
           autoplayTimeout ={2000}
          

           // Recursos de coruja mais importantes
    
    
           
           >  
           <div> <img className = "imgowl"  src = { '/img/pe.jpg' } alt="perola"  /> </div>
           <div> <img className = "imgowl"  src = { '/img/destro.png' } alt="destro"  /> </div>  
           <div> <img className = "imgowl"  src = { '/img/servimed.jpg' } alt="servimed" width="300"/> </div>  
           <div> <img className = "imgowl"  src = { '/img/rvb.jpg' } alt="rio vermelho"/> </div>  
           <div> <img className = "imgowl"  src = { '/img/spb.png' }alt="spani" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/btb.png' }alt="bentivi" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/jv.jpg' } alt="joinville"/> </div>  
           <div> <img className = "imgowl"  src = { '/img/gpz1.png' } alt="gpz" /> </div>
           <div> <img className = "imgowl"  src = { '/img/trb.png' } alt="TR" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/impacto.png' } alt="impacto" /> </div>
           <div> <img className = "imgowl"  src = { '/img/ecb.png' } alt="ecb" /> </div>   
           <div> <img className = "imgowl"  src = { '/img/logopronatub.png' } alt="pronatu" /> </div>
           <div> <img className = "imgowl"  src = { '/img/logo-soares.jpg' } alt="soares" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/weber4.png' } alt="weber" /> </div>       
           <div> <img className = "imgowl"  src = { '/img/pe.jpg' } alt="perola"  /> </div>
           <div> <img className = "imgowl"  src = { '/img/destro.png' } alt="destro"  /> </div>  
           <div> <img className = "imgowl"  src = { '/img/servimed.jpg' } alt="servimed" width="300"/> </div>  
           <div> <img className = "imgowl"  src = { '/img/rvb.jpg' } alt="rio vermelho"/> </div>  
           <div> <img className = "imgowl"  src = { '/img/spb.png' }alt="spani" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/btb.png' }alt="bentivi" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/jv.jpg' } alt="joinville"/> </div>  
           <div> <img className = "imgowl"  src = { '/img/gpz1.png' } alt="gpz" /> </div>
           <div> <img className = "imgowl"  src = { '/img/trb.png' } alt="TR" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/impacto.png' } alt="impacto" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/ecb.png' } alt="ecb" /> </div>  
           <div> <img className = "imgowl"  src = { '/img/logopronatub.png' } alt="pronatu" /> </div> 
           <div> <img className = "imgowl"  src = { '/img/logo-soares.jpg' } alt="soares" /> </div> 
           <div> <img className = "imgowl"  src = { '/img/weber4.png' } alt="weber" /> </div>          
      </OwlCarousel>  
      </div>  
      </div>  
        


        </div>
      </div>
    </div>
  )
}
