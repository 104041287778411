import React, { useState, useEffect } from "react";
import { About } from "../components/homepage/About";
import { Contact } from "../components/homepage/Contact";
import { Features } from "../components/homepage/Features";
import { Header } from "../components/homepage/Header";
 /* import { Navigation } from "../components/homepage/Navigation";  */
import { Navbar } from "../components/homepage/Navbar";
import { Services } from "../components/homepage/Services";
import { Gallery } from "../components/homepage/Gallery";
import { Team } from "../components/homepage/Team";
import { Testimonials } from "../components/homepage/Testimonials";
import JsonData from '../data/data.json'
import "../App.css"

const Homepage = () => {

  const [landingPageData, setLandingPageData]= useState({})
    useEffect(()=>{
        setLandingPageData(JsonData)
    },[])

  return (
    <div>
      <Navbar />
      <Header  data={landingPageData.Header}/>
      <About data={landingPageData.About}/>
      <Features data={landingPageData.Features}/>
      <Services data={landingPageData.Services}/>
      <Gallery/>
      <Testimonials  data={landingPageData.Testimonials}/>
      <Team data={landingPageData.Team}/>
      <Contact data={landingPageData.Contact}/>
      
    </div>
  );
};

export default Homepage;