export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Trabalhe conosco</h2>
          <br></br>
          <h3>Para ambas candidaturas, é necessário utilizar uma CONTA GOOGLE para LOGIN. Somente assim será possível validar as informações.</h3>
        </div>
        <div className='row'>
          <div className='col-md-6'>


            {/*   <!-- Botão que irá abrir o modal --> */}
            <button id='botaomodal' type="button" className="botaomodal btn btn-success btn-lg mt-2 ml-2" data-toggle="modal" data-target="#meuModal"><h3 className="h3trabalhe">Novos Agregados</h3></button>

            {/* <!-- Modal --> */}
            <div id="meuModal" className="modal fade" role="dialog">
              <div className="modal-dialog">

                {/* <!-- Conteúdo do modal--> */}
                <div className="modal-content">

                  {/*  <!-- Cabeçalho do modal --> */}
                  <div className="modal-header">
                    <h4 className="modal-title">Agregados</h4>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>

                  {/* <!-- Corpo do modal --> */}
                  <div className="modal-body">

                    <iframe title="My Daily Marathon Tracker" src="https://docs.google.com/forms/d/e/1FAIpQLScl6VcTWg-pB_r4LN60j4oVhV9QIahG6vQPPnE_U1cft6xCNg/viewform?embedded=true" width="550" height="650" /* frameborder="0"  marginheight="0" marginwidth="0"*/>Carregando…</iframe>
                  </div>
                  {/* 
          <!-- Rodapé do modal--> */}
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Fechar</button>
                    
                  </div>

                </div>
              </div>

            </div>

          </div>
          <div className='col-md-6'>


            {/* MODAL CANDIDATOS *

            {/*   <!-- Botão que irá abrir o modal --> */}
            <button id='botaomodal2' type="button" className="btn btn-success btn-lg mt-2 ml-2" data-toggle="modal" data-target="#meuModal2"><h3 className="h3trabalhe">Novos Candidatos</h3></button>


            {/* <!-- Modal --> */}
            <div id="meuModal2" className="modal fade" role="dialog">
              <div className="modal-dialog">

                {/* <!-- Conteúdo do modal--> */}
                <div className="modal-content">

                  {/*  <!-- Cabeçalho do modal --> */}

                  <div className="modal-header">
                    <h3 className="modal-title">Candidatos</h3>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>

                  {/* <!-- Corpo do modal --> */}
                  <div className="modal-body">


                    <iframe title="My Daily Marathon Tracker" src="https://docs.google.com/forms/d/e/1FAIpQLSdfcJTVSZqh11tmgonBaog7mh8UQnvMxNeHPVjafJ7f_RUptg/viewform?embedded=true" width="550" height="650" /* frameborder="0"  marginheight="0" marginwidth="0"*/>Carregando…</iframe>
                  </div>
                  {/* 
      <!-- Rodapé do modal--> */}
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Fechar</button>

                  </div>




                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>


  )
}




