import {
    BrowserRouter as 
    Route,
    
  } from "react-router-dom";
  

  import '../../App.css'
  

  
  export const Navbar = (props) => {
    return (

      
      <Route>
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
        
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
            
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='#page-top'><img className="imglogo" src="/img/logomada.png" width="70" alt="" /></a>
            </div>
  
          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              <li>
                <a href='#page-top' className='page-scroll'>
                  INÍCIO
                </a>
              </li> 
              <li>
                <a href='#about' className='page-scroll'>
                  EMPRESA
                </a>
              </li>
              <li>
                <a href='#services' className='page-scroll'>
                  REGIÕES 
                </a>
              </li>
              <li>
                <a href='#portfolio' className='page-scroll'>
                  FROTA
                </a>
              </li>
              <li>
                <a href='#testimonials' className='page-scroll'>
                  Trabalhe conosco
                </a>
              </li>
              <li>
                <a href='#team' className='page-scroll'>
                  Parceiros
                </a>
              </li>
              <li>
                <a href='#contact' className='page-scroll'>
                  Contato
                </a>
              </li>
              <li>
                <a href='/localiza' className='nav-link' id='Localizabotao'>
                  Localize sua carga
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      </Route>
    )
  }

  /* export const Navbar = (props) => {
    return (
      <nav className="navbar fixed-top navbar-expand-sm navbar-light bg-light">
        <div className="container">
          <a href="#" className="navbar-brand mb-0 h1">
            <img
              className="d-inline-block align-top"
              src="./img/logo/logo-tesourinhocolorido.png"
              href= "/"
              width="130"
            />
          </a>
          <button
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            className="navbar-toggler"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="navbar-item active">
                <a href="/" className="nav-link active">
                  Home
                </a>
              </li>
              <li className="navbar-item">
                <a href="/localiza" className="nav-link">
                  Localiza
                </a>
              </li>
              <li className="navbar-item">
                <a href="/register" className="nav-link">
                  Cadastre-se
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  };
   */