import { Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import {useState}from 'react'








function 
Alerta() {

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);








  return (
    <>
   



      <Modal className="corpoModal"  show={show}  onHide={handleClose}  animation={false} style={{color: "red"}} >
        <Modal.Header closeButton> 
          <Modal.Title style={{fontSize:'30px' , fontFamily:"arial", textAlign:'center'}} >ATENÇÃO</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background: "#2aa"}}>
          <div >
          <img className="imgalerta" src="/img/alerta.jpg" width='500' alt="agrega"/> 
       </div>
      <hr/>
       <div>
              
                    <a href='https://api.whatsapp.com/send?phone=5511975387091&text=Mada%20Log%C3%ADstica%20e%20transporte%20entre%20em%20contato%20para%20sanar%20duvidas!!!'>
                    <i class="fa fa-whatsapp fa-3x" aria-hidden="true" style={{color: "black"}}> Whatsapp</i>
                    </a>
                    
                    <h1 style={{display: "inline" , marginLeft: "8px" ,marginRight: "8px", fontSize:'25px'}} >ou</h1> 

                    <a class="btn btn-md btn-success" id="botaoficha" href="https://madalogistica.com/#testimonials">
  <i class="fa fa-flag fa-1x pull-left"></i> Envie sua ficha aqui</a>        
                


             </div>     
                 
                  </Modal.Body>      
              
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Fechar
          </Button>
          
        </Modal.Footer>

      </Modal>

   

    </>
  );
} 

  
 

export default Alerta;