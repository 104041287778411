/* import { useState } from 'react' */
//import emailjs from 'emailjs-com'

import Alerta from "./Alerta"

/* const initialState = {
  name: '',
  email: '',
  message: '',
} */
export const Contact = (props) => {
  /* const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    //emailjs
.sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  } */
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2 style={{textAlign: 'center'}}>Localização</h2>
               </div>
              <div className="modal-body">
                <iframe title="My Daily Marathon Tracker" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.0290511029625!2d-46.48153558440865!3d-23.45941656372198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce61d9ef8ee287%3A0x8d7a4e691f196e03!2sMada%20Log%C3%ADstica%20e%20transporte!5e0!3m2!1spt-BR!2sbr!4v1643973212827!5m2!1spt-BR!2sbr" width="600" height="450"  allowfullscreen="" loading="lazy">Carregando…</iframe>
             </div>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contatos</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Endereço
                </span>
                Rua Olária, 228 Cidade Industrial Satélite Guarulhos SP.
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefone
                </span>{' '}
                 (11)2382-1854 /
                 (11)24798475
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                sac@madalogistica.com
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href='https://www.instagram.com/madalogistica/?hl=pt'>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href='https://api.whatsapp.com/send?phone=5511975387091&text=Mada%20Log%C3%ADstica%20e%20transporte%20entre%20em%20contato%20para%20sanar%20duvidas!!!'>
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>
                  <li>
                    <a href= "https://www.facebook.com/madalogistica.mada.1">
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2021 Mada Logística
          </p>
           <Alerta></Alerta> 
        </div>
      </div>
    </div>
  )
}
