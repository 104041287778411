import Flippy, { FrontSide, BackSide } from 'react-flippy';

export const About = (props) => {
  return (
    
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
          {/*   {' '}
            <img src='img/empilhadeira.jpg' className='img-responsive' alt='empilhadeira' />{' '} */}
            <div className="flip">
             <Flippy
    flipOnHover={true} // default false
    flipOnClick={true} // default false
    flipDirection="horizontal" // horizontal or vertical
   /*  ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
    // if you pass isFlipped prop component will be controlled component.*/
   
   
  >
    <FrontSide>
      <img src='img/empilhadeira.jpg' className='img-responsive' alt='empilhadeira' />
    </FrontSide>
    <BackSide>
      <img src='img/logomada.png' className='img-responsive' alt='empilhadeira' />
    </BackSide>
  </Flippy>
  </div>
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>A Empresa</h2>
              <p><a rel="nofollow" href="/" target="_parent">Mada Logística</a> Fundada em 2009 , inicialmente como HRBC Transporte e logística situada nos dias de hoje na região de Guarulhos, atuando no segmento de transportes de cargas secas. Realizamos investimentos maciços em 
            em renovação de frota e capacitação profissional. Nossa filosofia de trabalho se baseia em qualidade,segurança e o melhor custo benefício se tornando símbolo de confiança e credibilidade junto aos seus clientes e priorizando sempre a segurança e garantindo a otimização dos seus serviços. Atualmente dispondo de um galpão de 5.000m² conseguindo atender todos 
            nossos parceiros.
            
             </p>
              <h3>Valores</h3>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
