import React, {Component} from "react";
import "../localiza.css"

export default class Localiza extends Component {

    state = {
      nf: "",
      cnpj: ""
    };
    
     getINFO = e => {
      var nf = this.state.nf;
      console.log(nf);
  
      var cnpj = this.state.cnpj;
      console.log(cnpj);
    } 
  
    getURL = () =>{
    
      window.location.href = 'https://web.bsoft.com.br/rastrear/notafiscal/35eLgQEWyd3VVHEF/'+ this.state.nf + '/' + this.state.cnpj; 
  
   
    }
  
   
    
    alterar = e => {
      this.setState({ [e.target.name]: e.target.value });
    };
  
    
  
     render() {
      return (
      <div className="App">
           <div className="content">
           <a href='https://madalogistica.com/'>
                      <img src="img/setavolta.png " style={{ position: 'fixed' ,

left: '10pt' ,  top:"10pt" , width: '80px'}} alt=""/>
                    </a>
           <div className="containerlocaliza">

           
           <img id="imgpesquisa" src="img/pesquisa.jpeg" alt="pesquisa" />

               
                <div className="localiza">
                  <img src="img/logomada.png" alt="logo" width="200px"/>
              <h1>Localize sua carga</h1>
              <div class="input_container">
              <input id="nf" name="nf" className="form__field" onChange={this.alterar} placeholder="NF"></input>
              <hr></hr> 
              <input id="cnpj" name="cnpj" className="form__field" onChange={this.alterar} placeholder="CNPJ"></input>
             </div>
              <hr></hr>
              <div clßassName="button-container">
                  <button onClick={this.getURL } id="btn-getnf" className="btn btn-flat btn-primary">Buscar</button>
    
                        </div>
              </div>
          </div>
          </div>
      </div>
      )}
  }