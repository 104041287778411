import React from "react";
import * as Routes from "./routes"
import { BrowserRouter as Router, Route } from "react-router-dom";





import Localiza  from "./pages/Localiza";
import Homepage from "./pages/Homepage";




const App = () => {
    


    return (
        <Router>
            <Route exact path= {Routes.Homepage} component= {Homepage}/>
            <Route exact path= {Routes.Localiza} component= {Localiza}/>
            
        </Router>
    );
};


export default App;
