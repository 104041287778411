export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Nosso diferencial</h2>
        </div> 
        <div className='row'>
        <div id="features" className="features section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="features-content">
            <div className="row">
              <div className="col-lg-3">
                <div className="features-item first-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
                  <div className="first-number number">
                    <h6>01</h6>
                  </div>
                  <div className="icon"></div>
                  <h4>Treinamento</h4>
                  <div className="line-dec"></div>
                  <p>Atualmente contamos com uma equipe treinada e qualificada desde a separação da carga até a entrega de forma segura e pontual com seu devido tratamento.</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="features-item second-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                  <div className="second-number number">
                    <h6>02</h6>
                  </div>
                  <div className="icon"></div>
                  <h4>Armazenamento</h4>
                  <div className="line-dec"></div>
                  <p>O serviço de armazenagem especializada com Infraestrutura diferenciada e sistemas de segurança adequados, Armazenamos sua carga de acordo com sua necessidade, armazém coberto, segurança, serviços de empilhadeira. </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="features-item third-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                  <div className="third-number number">
                    <h6>03</h6>
                  </div>
                  <div className="icon"></div>
                  <h4>Entregas Rapidas</h4>
                  <div className="line-dec"></div>
                  <p> Temos uma uma sofisticada estratégia de entrega, para sempre realizar as entregas dentro do prazo estabelecido. {/* <a rel="nofollow" href="https://www.paypal.me/templatemo" target="_blank"></a> */}</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="features-item fourth-feature last-features-item wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                  <div className="fourth-number number">
                    <h6>04</h6>
                  </div>
                  <div className="icon"></div>
                  <h4>Roteirização</h4>
                  <div className="line-dec"></div>
                  <p>Temos o setor de roteirização com o planejamento direto com foco no prazo de entrega, garantindo o cumprimento dos prazos.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  )
}
