export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Regiões Atendidas </h2>
          <p>
            Trabalhamos atualmente com entregas em todo o estado de São paulo
            
          </p>
        </div>
        <div className='row'>
        <img className="imgmapa" src="/img/mapa.png"  alt="mapa sp"/>
        </div>
      </div>
    </div>
  )
}
